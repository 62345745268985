export function validateEmail(input) {
  let email = input.value
  let params = new URLSearchParams()
  params.append('email', email)

  fetch('/form_helper/validate_email?' + params.toString(), { method: 'GET' })
    .then((response) => response.json())
    .then((data) => {
      let group = input.closest('.form-group, .t-field')
      const isTwField = group.classList.contains('t-field')
      group.classList.remove('has-error')

      let priorError = group.querySelector('.validate-email')
      if (priorError) {
        priorError.remove()
        if (isTwField) {
          group.querySelector('.help-block').classList.add('hidden')
        }
      }

      if (email != input.value) {
        // It's changed already, forget it.
        return
      }

      let errorMessage =
        data.status === 'undeliverable'
          ? 'This email is invalid'
          : data.status === 'risky' && data.reason === 'low_quality'
          ? 'This email looks incorrect'
          : void 0
      if (errorMessage != null) {
        if (isTwField) {
          group.querySelector('.help-block').classList.remove('hidden')
          const errorDiv = group.querySelector('.error-message')
          const span = document.createElement('span')
          span.classList.add('validate-email')
          span.innerText = errorMessage
          errorDiv.appendChild(span)
        } else {
          let errorDiv = document.createElement('p')
          errorDiv.classList.add('help-block', 'validate-email')
          let span = document.createElement('span')
          span.classList.add('error-message')
          span.appendChild(document.createTextNode(errorMessage))
          errorDiv.appendChild(span)
          input.insertAdjacentElement('afterend', errorDiv)
        }
      }
    })
}
